$top-bar-z-index: 1003 !default;

.hamburger {
  border: none;
  outline: none;
  cursor: pointer;
  z-index: $top-bar-z-index;
  position: fixed;
  transition: $transition-base;
  padding: 0;
  //top: map-get($top-bar-height, mobile);
  top: 7px;
  right: 0;
  background: transparent;

  &:focus {
    outline: none;
  }

  &.is-active {
    .hamburger-inner,
    .hamburger-inner::after,
    .hamburger-inner::before {
      background-color: $link-color;
    }
  }
}

//#region hamburger size
.hamburger {
  width: 44px;
  height: 44px;
}

.hamburger-inner,
.hamburger-inner::after,
.hamburger-inner::before {
  background-color: $link-color;
  height: 3px;
  width: 28px;
}

.hamburger-box {
  width: 28px;
}

.hamburger-inner::before {
  top: -8px;
}

.hamburger--collapse .hamburger-inner::after {
  top: -16px;
}

@include media-breakpoint-up(lg) {
  .hamburger {
    //top: map-get($top-bar-height, desktop);
    top: 9px;
    width: 56px;
    height: 56px;
  }

  .hamburger-inner::before {
    top: -9px;
  }

  .hamburger--collapse .hamburger-inner::after {
    top: -18px;
  }

  .hamburger-inner,
  .hamburger-inner::after,
  .hamburger-inner::before {
    //background-color: white;
    height: 3px;
    width: 34px;
  }

  .hamburger-box {
    width: 34px;
  }

}

//#endregion
