.wanderers {
  #mobile-menu-icon, #sliding-menu, .topNav__logo.logo1, .topNav__logo.logo2, .common-footer, .bottomMenuWrapper, .commonParallax {
    display: none;
  }

  .single-banner.banner-item {
    background-image: url('/assets/wanderers-banner.jpg') !important;
  }

  .topNav__logo {
    transition: all 0.5s ease-in-out;
    font-size: 1.5rem;
    width: 90%;
    text-align: center;
    line-height: 2;
    font-weight: bold;
  }
}