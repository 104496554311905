@import './mixins';
@import './variables';

// plugins
@import './bootstrap/bootstrap';
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
//@import '~react-day-picker/lib/style.css';
//@import '~react-modal-video/scss/modal-video.scss';

//@import './fonts';
@import './base';
@import './sections';
@import './form';

// top
@import './layout/hamburger';
@import './layout/top';
@import './layout/top-navbar';

// bottom
@import './layout/bottomPanel';
@import './layout/bottomMenu';

@import './cms/cms-site';

// modules
@import './modules/breadcrumb';
@import './modules/rotatingBanner';
@import './modules/bannerSize';
@import './modules/pageTiles';
//@import './modules/blogTiles';
@import './modules/slider';

@import './modules/youtube';

// components
@import '../components';

@import './site/index';


.container {
    @include media-breakpoint-up(xl) {
        max-width: 1140px;
    }
}

a.topNav__back svg {
    margin-top: -3px;
}


div.appInfo {
    text-align: center;
}

body.SRLOpened #SRLLightbox {
    z-index: 9999;
}

.grecaptcha-badge {
    visibility: hidden;
}

// .testimonialPanel .slick-list{
//     display: flex !important;
// }

// .slick-initialized .slick-slide > div{
//     height: 100%;
// }