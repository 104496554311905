.banner-item {
  max-width: 100vw;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
}

.banner_item__text, .banner-item__link {
  font-family: $family-header;
  color: #fff;
  font-size: 1.6rem; 
  
  @include media-breakpoint-up(lg) {
    font-size: 1.8rem;
  }
}

.banner_item__text {
  background: rgba(#000, 0.1);
  text-align: center;
  padding: 0.5rem 0 0.7rem;
  position: absolute;
  bottom: 0;
  width: 100%;
  .banner-item__title{
    display: inline-block;
    padding: 0px 2.5rem;
    background-color: $primary-color;
    line-height: 43px;
    padding-bottom: 4px;
    border-radius: 10px;
    font-weight: $weight-bold;
  }
}

.banner-item__link {
  &:hover, &:focus {
    color: #fff;
  }
}