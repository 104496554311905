.invalid {
  font-size: 85%;
  color: red;
}

// overriding bootrap invalid text box (remove x icon)
.was-validated .form-control:invalid, .form-control.is-invalid {
  background:none;
}


.editor {
  max-width: 960px;
  margin-left: auto;
  margin-right: auto;

  .label {
    margin-bottom: 0.1rem;
    font-size: 0.75rem;
    color: #666;
  }

  .form-group {
    margin-bottom: 0.3rem;
  }

  .actions {
    margin: 1.5rem 0;
    text-align: left;

    button,
    a {
      margin-right: 0.9rem;
    }
  }

  .help {
    font-size: 65%;
    margin: 0.1rem 0 0.5rem;
    color: chocolate;
  }
}

.htmlEditor {
  textarea.form-control {
    max-height: none;
    min-height: 300px;
  }

  &__panel {
    display: none;

    &.active {
      display: block;
    }
  }

  &__view {
    margin-top: 3px;
    text-align: right;

    button {
      font-size: 0.75rem;

      
    }
  }
}


.form-check-input {
  margin-top: 0.6rem;
}

.form__actions {
  margin: 0.5rem 0;
}

label.label {
  display: block;
}

.listMin3 .form-check-inline {
  min-width: 30%;
}

@import 'steps';