@import './shop/storeList';
@import './product/productFilterSort';
@import './product/productList';
@import './product/productView';
@import './blog/blogList';
@import './blog/blogView';
@import './file/fileLink';
@import './search';
@import './wanderers';

.review_summary {
  margin-top: 2rem;
  [data-bv-show="rating_summary"] {
    text-align: center !important;
  }
}

.youtubeEmbedded {
  max-width: 960px;
  margin: auto;
}

.common-footer {
  a {
    color: white;
  }
}