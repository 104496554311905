.tippingStanding {
  max-width: 960px;
  margin: auto;
  font-size: 16px;

  th {
    padding: 0.3rem 0;
    border-bottom: solid 1px #aaa;
  }

  td {
    padding: 0.3rem 0;
    vertical-align: top;
  }

  .rank_td {
    width: 5rem;

  }

  .rank_rank {
    font-weight: bold;
    text-transform: uppercase;
  }

  .rank_score {
    font-size: 80%;
  }

  .rank_user {
    &.me {
      font-weight: bold;
      color: red;
    }
  }

  @media (max-width: 767.98px) {
    th {display: block; border: none;
    }

    table,
    td {
      display: block;
    }

    .rank_td {
      width: 100%;
      border-top: solid 1px #ccc;
    }
  }
}