$top-bar-z-index: 1000 !default;

.topNav {
  transition: all 0.2s ease-in-out;
  position: fixed;
  //background: #fff;
  //background-color: transparent;
  background-color: rgba($color: #fff, $alpha: 0.8);
  z-index: $top-bar-z-index;
  height: 56px;
  top: 0;
  width: 100%;
  font-size: 0.75rem;
  .topNav__logo{
    transition: all 0.5s ease-in-out;
    img {
      width: 100px;
      @include media-breakpoint-up(lg) {
        width: 150px;
      }
    } 
  }

  .topNav__logo.logo2{
    //display: none;
    transition: all 0.9s ease-in-out;
    opacity: 0;
    visibility: hidden;
    img {
      width: 200px;
      @include media-breakpoint-up(lg) {
        width: 240px;
      }
    } 
  }

  @include media-breakpoint-up(lg) {
    //height: map-get($top-bar-height, desktop);
    //height: 56px;
    height: 70px;
  }
}

a.topNav__back {
  position: absolute;
  left: 1rem;
  //line-height: map-get($top-bar-height, mobile);
  color: $link-color;
  top: 50%;
  transform: translateY(-50%);
  &:hover {
    color: $link-color;
    text-decoration: none;
  }
  // @include media-breakpoint-up(lg) {
  //   line-height: map-get($top-bar-height, desktop);
  // }
}

.topNav__siteSwitch {
  position: absolute;
  right: 1.5rem;
  line-height: map-get($top-bar-height, mobile);
  @include media-breakpoint-up(lg) {
    right: 2rem;
    line-height: map-get($top-bar-height, desktop);
  }
}

.topNav__sep {
  display: inline-block;
  padding: 0 1rem;
  color: white;
}

body.scrolled{
  .topNav .topNav__logo.logo1{
    top: -155px;
    opacity: 0;
  }
  .topNav .topNav__logo.logo2{
    //display: block;
    opacity: 1;
    visibility: visible;
  }
}