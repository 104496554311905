// overriding bootstrap variables in _boostrap-override.scss

$family-base: "proxima-nova", sans-serif;
$family-header: $family-base;

$weight-light: 300;
$weight-regular: 400;
$weight-medium: 500;
$weight-semi-bold: 600;
$weight-bold: 700;
$weight-normal: $weight-light;

// colors - sister colours from bootrap
// $color-blue:    #007bff;
// $color-red:     #fe3a43;
// $color-orange:  #fd7e14;
// $color-green:   #28a745;

// more colors
//$color-teal: #30b0c5;
$color-gold: #c78346;
$color-dark-gray: #3d3d3d;
$color-black: black;
$color-green : #007047;
$color-yellow : #ffc21e;
$color-light-grey : #ebebeb;

// site colors
$primary-color: $color-green;
$font-color: $color-dark-gray;
$font-color-white: #fff;
$header-color: $font-color;
$bg-color:$color-light-grey;

$link-color: $primary-color;
$link-hover-color: $color-yellow;

// top-bar
$top-bar-border: (mobile: 5px, desktop: 12px);
$top-bar-height: (mobile: 44px, desktop: 56px);

// base transition
$transition-base: all 0.3s ease-in-out;
$transition-fast: all 0.2s ease-in-out;
$transition-slow: all 0.5s ease-in-out;