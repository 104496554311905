.main-content {
  //padding-top: map-get($top-bar-height, mobile);
  @include media-breakpoint-up(lg) {
    //padding-top: map-get($top-bar-height, desktop);
  }
}

.actions {
  margin: 1rem 0;
  text-align: center;
  button {
    //margin-right: 0.7rem;
    font-family: $family-header;
    font-weight: $weight-bold;
    border-radius: 50px;
    padding-bottom: 5px;
    &:hover{
      background-color: $link-hover-color;
      border-color: $link-hover-color;
      color: $font-color;
    }
  }
}